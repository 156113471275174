import type { Plugin } from "vue";

type Modal = {
  hide: () => void;
  name: string;
  show: () => void;
};

type SModal = {
  findSubscriber: (name: string) => Modal | undefined;
  hide: (name: string) => void;
  modals: Modal[];
  show: (name: string) => void;
  subscribe: (ref: Modal) => void;
  unsubscribe: (ref: Modal) => void;
};

const sModal: Plugin = (app) => {
  app.config.globalProperties.$sModal = {
    modals: [] as Modal[],
    show(name: string) {
      const modal = this.findSubscriber(name);
      if (!modal) {
        return;
      }
      modal.show();
    },
    hide(name: string) {
      const modal = this.findSubscriber(name);
      if (!modal) {
        return;
      }
      modal.hide();
    },
    findSubscriber(name: string) {
      return this.modals.find((modal: Modal) => modal.name === name);
    },
    subscribe(ref: Modal) {
      this.modals.push(ref);
    },
    unsubscribe(ref: Modal) {
      const index = this.modals.findIndex(
        (modal: Modal) => modal.name === ref.name,
      );
      if (index === -1) {
        console.warn(`[SModal] ${ref.name} is not subscribed`);
        return;
      }
      this.modals.splice(index, 1);
    },
  } satisfies SModal;
};

export default sModal;
